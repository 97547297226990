var TrackerType = {
    Functional: 'functional',
    Preferences: 'preferences',
    Statistical: 'statistical',
    Marketing: 'marketing',
    Essential: 'essential'
};

const cookieName = 'dlrg-cookie-consent';

var Tracker = function (type, name, description, callback) {

    var ensureTypeIsValid = function (type) {
        if ([TrackerType.Functional, TrackerType.Statistical, TrackerType.Marketing, TrackerType.Essential].indexOf(type) === -1) {
            throw new TypeError('invalid tracker type');
        }
    };

    var ensureCallbackIsValid = function (callback) {
        if (typeof callback !== 'function') {
            throw new TypeError('callback property has to be a function');
        }
    };

    ensureTypeIsValid(type);
    ensureCallbackIsValid(callback);

    this.getType = function () {
        return type;
    };
    this.getName = function () {
        return name;
    };
    this.getDescription = function () {
        return description;
    };
    this.getCallback = function () {
        return callback;
    };
    this.getConsent = function () {
        var consentSelection;
        if (!!(sessionStorage.getItem(cookieName))) {
            consentSelection = JSON.parse(sessionStorage.getItem(cookieName))[name];
        } else if (!!(localStorage.getItem(cookieName))) {
            consentSelection = JSON.parse(localStorage.getItem(cookieName))[name];
        } else {
            consentSelection = false;
        }
        return consentSelection;
    }
};

var CookieConsent = function () {

    var trackerList = {};
    function execueteTracker() {
        for (let track in trackerList) {
            if (trackerList[track].consent) {
                trackerList[track].callback();
            }
        }
    }

    var ensureTrackerIsValid = function (tracker) {
        if (tracker instanceof Tracker === false) {
            throw new TypeError('property tracker has to be an instanceof of Tracker');
        }
    };

    this.addTracker = function (tracker) {

        ensureTrackerIsValid(tracker);

        trackerList[tracker.getName()] = {
            consent: tracker.getConsent(),
            description: tracker.getDescription(),
            callback: tracker.getCallback(),
            type: tracker.getType()
        };

    };

    var listTracker = function () {
        for (var tracker in trackerList) {
            var area = document.getElementById("cc-" + trackerList[tracker].type);
            area.classList.remove('cc-d-none');
            var table = area.getElementsByTagName('table')[0];

            var tr = document.createElement('tr');
            var tdName = document.createElement('td');
            tdName.appendChild(document.createTextNode(tracker));
            tr.appendChild(tdName);
            var tdDescription = document.createElement('td');
            tdDescription.innerHTML = trackerList[tracker].description;
            tr.appendChild(tdDescription);
            table.appendChild(tr);
        }
    };


    this.run = function () {

        if (Object.keys(trackerList).length === 0) {
            return;
        }

        if (navigator.doNotTrack === '1') {
            return;
        }

        if (!!(sessionStorage.getItem(cookieName))) {
            //Cookie already exist
            execueteTracker();
            return;
        }

        if (!!(localStorage.getItem(cookieName))) {
            //Cookie already exist
            var CookieContent = JSON.parse(localStorage.getItem(cookieName));

            //Check if consent informations are missing in localStorage / sessionStorage
            for (let tool in trackerList) {
                if (CookieContent[tool] === undefined) {
                    // Consent informations are missing in localStorage
                    console.warn("Consent information is missing in localStorage. Please restart your browser.");
                    // Transfer information to session Storage and ask User again in next session
                    sessionStorage.setItem(cookieName, JSON.stringify(CookieContent));
                    localStorage.removeItem(cookieName);
                }
            }
            execueteTracker();
            return;
        }



        var footer = document.getElementsByTagName('footer')[0];
        footer.classList.add('cookie-consent');
        var popup = document.createElement('div');
        popup.id = 'cookie-consent';
        var templateClone = document.adoptNode(document.getElementById('cc-template'));
        popup.appendChild(templateClone.firstElementChild);
        footer.insertBefore(popup, footer.firstChild);

        var saveButton = document.getElementById('cc-save');
        if (Object.keys(trackerList).length > 0) {
            saveButton.classList.remove("cc-d-none");
        }

        listTracker();

        const countEssentials = document.getElementById("cc-essential").getElementsByTagName('tr').length;
        document.getElementById('cc-allow-all').addEventListener('click', function (cookieName) {
            return function (e) {
                e.preventDefault();
                var cookieString = {};
                for (let tracker in trackerList) {
                    trackerList[tracker].consent = true;
                    cookieString[tracker] = trackerList[tracker].consent;
                }
                localStorage.setItem(cookieName, JSON.stringify(cookieString));
                execueteTracker();
                popup.remove();
            };
        }(cookieName));

        (function () {
            // automatic change of essential checkbox for Tag Manager
            function changeEssential() {
                // avoid accepting value in cookie for essentials if there is no essential element
                // TODO: Remove Checkbox in HTML & Cleanup Code
                if (countEssentials != 0) {
                    if (document.getElementById("cc-statistical-check").checked) {
                        document.getElementById("cc-essential-check").checked = true;
                        return;
                    }
                    if (document.getElementById("cc-marketing-check").checked) {
                        document.getElementById("cc-essential-check").checked = true;
                        return;
                    }
                }
                document.getElementById("cc-essential-check").checked = false;
            }
            var inputElements = document.getElementById("cookie-consent");
            for (let el of inputElements.getElementsByTagName("input")) {
                el.addEventListener('click', changeEssential);
            }
        }());

        (function () {
            if (document.getElementById('cc-deny-all') != null) {
                document.getElementById('cc-deny-all').addEventListener('click', function (cookieName) {
                    return function (e) {
                        e.preventDefault();
                        var cookieString = {};
                        for (let tracker in trackerList) {
                            cookieString[tracker] = false;
                        }
                        localStorage.setItem(cookieName, JSON.stringify(cookieString));
                        popup.remove();
                    };
                }(cookieName));
            }
        }());
        document.getElementById('cc-show-details').addEventListener('click', function () {
            document.getElementById('cc-details').classList.toggle('cc-d-none');
            if (this.innerText == 'weniger Details') {
                this.innerText = 'mehr erfahren';
            } else {
                this.innerText = 'weniger Details';
            }
        });

        saveButton.addEventListener('click', function (cookieName) {
            return function (e) {
                e.preventDefault();
                status = {
                    stats: document.getElementById('cc-statistical-check').checked,
                    marketing: document.getElementById('cc-marketing-check').checked,
                    essential: document.getElementById('cc-essential-check').checked
                };
                var cookieString = {};
                for (let tracker in trackerList) {
                    //create JSON for API
                    let checked = document.getElementById('cc-' + trackerList[tracker].type + '-check').checked;
                    cookieString[tracker] = checked;
                };
                popup.remove();
                execueteTracker();
                for (let tracker in cookieString) {
                    // Save to local storage, if user does not accept all Cookies
                    if (!(cookieString[tracker])) {
                        localStorage.setItem(cookieName, JSON.stringify(cookieString));
                        return;
                    }
                }
                localStorage.setItem(cookieName, JSON.stringify(cookieString));

            };
        }(cookieName));
    };
};

/**********************************************/
/*          Sticky Menu                       */
/**********************************************/

$('.sticky-seite').on('click', function () {
    $(this).toggleClass("open");
});

$(document).click(function (e) {
    var target = $(e.target);

    if ($(target).closest('.sticky-seite').length === 0) {
        $('.sticky-seite').removeClass('open');
    }
    if ($(target).closest('.searcharea').length === 0 && $(target).closest('.openCloseSearch').length === 0) {
        $(".searcharea").slideUp();
    }

});

/**********************************************/
/*          Links                             */
/**********************************************/
// On loading a page, with a hash (ID) in URL, scroll there -125px.
if (location.hash) {
    let uri = window.location.toString()
    let hash = uri.substring(uri.indexOf("#") + 1, uri.length)
    let clean_uri = uri.substring(0, uri.indexOf("#"))

    //Can't rely on browser scrolling, because Chrom(e/ium) scrolls after DOMCOntentLoaded...
    window.scrollBy({
        top: document.getElementById(hash).getBoundingClientRect().top - 125,
        behavior: "auto"
    })

    window.history.replaceState({}, document.title, clean_uri)
}

// On clicking a link, which has an ID as href-target, scroll there -125px.
document.getElementsByTagName('a').forEach((element) => {
    // Exclude all content element, that control a js collapsible element!
    let collapsible = element.getAttribute('data-toggle') === 'collapse'

    if(!collapsible) {
        let uri = element.href.toString()
        let hash = uri.substring(uri.indexOf("#") + 1, uri.length)
        let linkpath = uri.substring(0, uri.indexOf('#'))
        let localpath = window.location.toString()

        if (hash.length > 0 && linkpath === localpath) {
            element.addEventListener('click', function (event) {
                event.preventDefault()
                window.scrollBy({
                    top: document.getElementById(hash).getBoundingClientRect().top - 125,
                    behavior: "smooth"
                })
            })
        }
    }
})

/**********************************************/
/*          Dropdown                          */
/**********************************************/

//Startseite Mapkategorie auswählen (ZWRDK)
$(".map-dropdown-menu").on('click', 'a', function (event) {
    event.preventDefault();
    var selectButton = $(this).parent().parent().find(".map-btn-select");
    $(selectButton).text($(this).text());
    $(selectButton).val($(this).text());
});

/**********************************************/
/*          Bilder - Fancybox                 */
/**********************************************/
fancyboxBilder = document.querySelectorAll("a[rel=group]")

if (fancyboxBilder.length > 0) {
    fancyboxBilder.forEach(bild => {
        if (!bild.hasAttribute("data-fancybox")) {
            bild.setAttribute("data-fancybox", "t3gallery")
        }
    })
}

/**********************************************/
/*          Spendenleiste + Seite             */
/**********************************************/
const spendenElement = document.getElementById('spendenElement');

if (spendenElement != null) {
    const spendenBetrag = document.getElementById('spendenbetrag');
    const spendenButton = document.getElementById('spendenbutton');
    const spendenSlider = document.getElementById('spendenslider');
    const spendenBilder = document.getElementById('spendenbilder');

    ['change', 'mousemove'].forEach((event) => {
            spendenSlider.addEventListener(event, () => {
                spendenBetrag.value = spendenSlider.value
                spendenBetrag.dispatchEvent(new Event('change'));
            }, false)
        }
    );

    ['change', 'input'].forEach((event) => {
            spendenBetrag.addEventListener(event, () => {
                if (spendenElement.classList.contains('spendenElement-big')) {
                    spendenSlider.value = spendenBetrag.value

                    let spendenbild //Define here to have correct variable-scope. (Block scope would limit inside for-loop)
                    for (let ele in spendenelemente) {
                        if (ele > parseInt(spendenBetrag.value)) {
                            break
                        }
                        spendenbild = spendenelemente[ele]
                    }

                    spendenBilder.firstElementChild.setAttribute("src", spendenbild[1]) //image
                    spendenBilder.lastElementChild.innerHTML = spendenbild[0] //div
                }

                spendenButton.href = spendenlink + (spendenlink.indexOf("?") === -1 ? '?' : '&') + spendenparameter + "=" + spendenBetrag.value
            }, false)
        }
    );
}

/**********************************************/
/*           Spendenpatenschaft               */
/**********************************************/

const $element = $('.patenschaft input[type="range"]');
const $tooltip = $('.patenschaft .range-tooltip');
const sliderStates = [{
        name: "low",
        range: _.range(5, 26)
    },
    {
        name: "med",
        range: _.range(26, 50)
    },
    {
        name: "high",
        range: [60]
    },
];
var currentState;
var $handle;
var $total;
$element
    .rangeslider({
        polyfill: false,
        onInit: function () {
            updateHandle();
        }
    })
    .on('input', function () {
        updateHandle();
    });

function updateHandle() {
    const singles = document.querySelectorAll('.patenschaft.single');
    const multiples = document.querySelectorAll('.patenschaft.multiple');

    if (singles.length > 0) {
        singles.forEach(single => {
            var slider = single.querySelector('input[type="range"]');
            var output = single.querySelector('output');
            var changingElements = single.querySelectorAll('.donateval');

            output.value = slider.value + ',00 €';

            changingElements.forEach(element => {
                element.style.display = 'none';
                if (element.getAttribute('data-value') == slider.value) {
                    element.style.display = 'block';
                }
            });
        });
    }

    if (multiples.length > 0) {
        multiples.forEach(multiple => {
          	let minimum = parseInt(multiple.querySelector('#patenschaftMinimum').getAttribute('data-minimum'));
          	let submit = multiple.querySelector('input[name="submit"]');
            let rows = multiple.querySelectorAll('.auswahl .row');
            let sum = 0;
            let sumElement = multiple.querySelector('.sum');
            let amountButton = sumElement.querySelector('button');

            rows.forEach(row => {
                let slider = row.querySelector('input[type="range"]');
                let output = row.querySelector('output');

                output.value = slider.value + ',00 €';
                sum = parseInt(slider.value) + sum;
            });
			
          	if (parseInt(sum) < minimum) {
                submit.setAttribute('disabled', '');
                amountButton.setAttribute('disabled', '');
            } else {
                submit.removeAttribute('disabled');
                amountButton.removeAttribute('disabled');
            }
          
            amountButton.textContent = sum + ',00€/monatl.';
            sumElement.querySelector('input[name="amount"]').value = sum;
        });
    }
}

/**********************************************/
/*                Onlinespenden               */
/**********************************************/
window.onlinespendenQuittung = function(value) {
    var optionalQuittung = document.querySelectorAll(".optionalQuittung");

    optionalQuittung.forEach(row => {
        var inputs = row.querySelectorAll('input');

        if (value == "Sofort nach Geldeingang") {
            row.classList.remove("d-none");
            inputs.forEach(input => {
                input.setAttribute("required", "");
            });
        } else {
            row.classList.add("d-none");
            inputs.forEach(input => {
                input.removeAttribute("required");
            });
        }
    });
}

window.onlinespendenAnrede = function (value) {
    var optionalFirma = document.querySelectorAll(".optionalFirma");
    var optionalFamilie = document.querySelectorAll(".optionalFamilie");

    optionalFirma.forEach(row => {
        if (value == "Stiftung" || value == "Firma") {
            row.classList.remove("d-none");
        } else {
            row.classList.add("d-none");
        }
    });

    optionalFamilie.forEach(row => {
        var requiredInputs = row.querySelectorAll("input.required");

        if (value == "Familie") {
            row.classList.add("d-none");
            requiredInputs.forEach(input => {
                input.removeAttribute("required");
            });
        } else {
            row.classList.remove("d-none");
            requiredInputs.forEach(input => {
                input.setAttribute("required", "");
            });
        }
    });
}

// Run functions once on page load to ensure correct view.
if(document.querySelector('#spenderAnrede') != null) {
	onlinespendenAnrede(document.querySelector('#spenderAnrede').value);
}

if(document.querySelector('#spendenquittung') != null) {
	onlinespendenQuittung(document.querySelector('#spendenquittung').value);
}

/**********************************************/
/*  3Spalten Bild/Text Element zum ausklappen */
/**********************************************/

$('.DreiSpalten-textBild .box').on('click', function (event) {
    event.preventDefault();
    var container = $(this).attr("data-collapsebox");

    if ($('.DreiSpalten-textBild').find('.collapse' + container).is(':visible')) {
        $('.DreiSpalten-textBild').find('.collapse' + container).slideUp();
        $(this).find('a div.plus').removeClass("active");
    } else if (!$('.DreiSpalten-textBild').find('.collapse').is(':visible')) {
        $('.DreiSpalten-textBild').find('.collapse' + container).slideDown();
        $(this).find('a div.plus').addClass("active");
    } else {
        $('.DreiSpalten-textBild').find('a div.plus').removeClass("active");
        $('.DreiSpalten-textBild').find('.collapse').slideUp(600);
        $(this).find('a div.plus').addClass("active");
        setTimeout(function () {
            $('.DreiSpalten-textBild').find('.collapse' + container).slideDown();
        }, 650);
    }
});

/**********************************************/
/*          Teaserbox                         */
/**********************************************/

var showteaserboxinterval = null;

$('.teaserbox').hover(function () {
    var teaserbox = $(this);
    var count = 0;
    showteaserboxinterval = setInterval(function () {
        if (count == 0) {
            clearInterval(showteaserboxinterval);
            teaserbox.find('.imgTransparent').animate({opacity: "0.5"}, 600);
            teaserbox.find('.hovertext').slideDown(600, function () {
                $(this).find('.img-cont.text-bottom').animate({bottom: "35%"}, 600);
            });
        }
        count++;
    }, 300);
}, function () {
    if (showteaserboxinterval) {
        clearInterval(showteaserboxinterval)
    }
    $(this).find('.imgTransparent').animate({opacity: "0.7"}, 600);
    $(this).find('.hovertext').slideUp(600, function () {
        $(this).find('.img-cont.text-bottom').animate({bottom: "15px"}, 600);
    });
});

/**********************************************/
/*          Gallery Teaser                    */
/**********************************************/

$('#gallerylist .teaser').parent().hover(function () {
    $(this).find(".teaser").slideDown("800");
}, function () {
    $(this).find(".teaser").hide();
});

/**********************************************/
/*          Divider Auswahl                   */
/**********************************************/

$('.divider_picText').find('.openCloseHoverbox').on('click', function (event) {
    event.preventDefault();
    $(this).parent().find('.hoverbox').slideToggle();
    $(this).children('.plus').toggleClass("active");
});

/**********************************************/
/* Spendenshop: Warenkorb stuff.  */
/**********************************************/
let isThanksPage = (document.getElementById('spendenshopThanks') != null)
let stickyBasket = document.getElementById("spendenshopStickyBasket")

if (stickyBasket != null && !isThanksPage) {
    fetch(stickyBasket.getElementsByClassName('basket-link')[0].dataset.refreshurl, {
        method: 'POST',
    }).then((json) => json.json()).then((response) => {
      	let dataKey = 'data-ajax-refresh-target';
        stickyBasket.querySelector("[" + dataKey + "='basket-totalPrice']").innerHTML = response.totalPrice
        stickyBasket.querySelector("[" + dataKey + "='basket-totalQuantity']").innerHTML = response.totalQuantity;
        
      	if (response.totalQuantity !== 0) {
            stickyBasket.classList.remove('d-none') //Enable basket if it is not empty, as it is not displayed by default.
        }
    }).catch((err) => {
        console.error(err)
    })
}

//=require ../../node_modules/jquery/dist/jquery.min.js
//=require ../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js
//=require ../../node_modules/jquery-touchswipe/jquery.touchSwipe.min.js
//=require ../../node_modules/shariff/dist/shariff.min.js
//=require ../../node_modules/iframe-resizer/js/iframeResizer.min.js
//=require ../../node_modules/bootstrap-table/dist/bootstrap-table.min.js

//Fullcalendar
//=require ../../node_modules/fullcalendar/main.min.js
//=require ../../node_modules/fullcalendar/locales/de.js

//=require ../../node_modules/rangeslider.js/dist/rangeslider.min.js
//=require ../../node_modules/underscore/underscore-min.js

//Dokument fertig geladen
document.addEventListener("DOMContentLoaded", function(event) { 
    //=require partial/head.js
    //=require partial/menu.js
    //=require partial/content.js
    //=require partial/footer.js
});

//=require ../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.js
//=require ../../node_modules/bootbox/dist/bootbox.min.js
//=require partial/cookie-consent.js
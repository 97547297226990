/**********************************************/
/*          Navigation                        */
/**********************************************/

//Nav toplevel
$('header .container.mainnav nav ul.navbar-nav').children('.nav-item').each(function () {
    var t = null;
    var li = $(this);
    var showmenuinterval = null;

    li.hover(function () {
        var count = 0;
        showmenuinterval = setInterval(function () {
            if (count == 0) {
                clearInterval(showmenuinterval);

                t = setTimeout(function () {
                    if (li.find(".navhover .level2.subnav1").length) {
                        li.find(".navhover").css('marginTop', "-5px");
                        li.find(".navhover").show(0);
                        li.find(".navhover").animate({
                            'marginTop': '0'
                        }, 500);
                    }
                    li.find("a").first().addClass("active");
                    t = null;
                }, 0);
            }
            count++;
        }, 200);
    }, function () {
        if (showmenuinterval) {
            clearInterval(showmenuinterval)
        }
        if (t) {
            clearTimeout(t);
            t = null;
        } else {
            li.find(".navhover").hide(0);
            li.find("a").first().removeClass("active");
        }
    });
});

//Navigation open Third Level
$('ul.level2 li').hover(function () {
    $(this).children('ul.level3').addClass('active');
    $(this).children('ul.level3').parent().addClass('active');

    //Menü höhe anhand des 3.Levels
    var heightOfLevel3 = $(this).children('ul.level3').outerHeight(true);
    var heightOfNav = $(this).parent().parent().parent().height();

    if (heightOfNav > heightOfLevel3) {
        $(this).children('ul.level3').css("height", "100%");
    } else {
        $(this).parent().parent().parent().css("height", heightOfLevel3 + "px");
    }
}, function () {
    $(this).children('ul.level3').removeClass('active');
    $(this).children('ul.level3').parent().removeClass('active');
    $(this).children('ul.level3').removeAttr('style');
    $(this).parent().parent().parent().css("height", "auto");

});

$(".dropdown-menu.level2").addClass("p-0").addClass("m-0");

//Open Mobile Menu open second Level
$('#mobileMainMenu .showLevel2').on("click", function () {
    $(this).parent().children("ul").addClass("active");
});


//Menu open menupoints mobile
$('#navbarMainMenuMobile .dropdown-menu').find("a").on('click', function (event) {
    location.href = $(this).attr("href");
});


//Suche in Mobiles Menü einfügen
$("#navbarMainMenuMobile").prepend("<div class='menusearchontainer'></div>");
$("#navbarMainMenuMobile").find(".menusearchontainer").prepend($(".searcharea").find("form").clone(true));
$("#topSearch").attr("placeholder", "Webseite durchsuchen")


$("header .container.mainnav #navbarMainMenu .nav-item .dropdown-menu").each(function () {
    var subMenu = $(this).clone(true);
    $(subMenu).removeClass("dropdown-menu");
    $(subMenu).addClass("subnav1");
    $(this).parent().find(".navhover .flex").append(subMenu);
    $(this).remove();
    subMenu = "";
});

$("header .container.mainnav #mobileMainMenu .nav-item .dropdown-menu").each(function () {
    $(this).parent().append("<span class='showLevel2'><i class='fa fa-chevron-right textWhite text-white'></i></span>");
});


/*Mobile Arrows*/
$('header .container.mainnav.mobilenav #navbarMainMenuMobile ul.level3').parent().children("a").find(".arrow_submenu").parent().remove();


//Open Mobile Menu
$('header .container.mainnav.mobilenav .navbar-toggler').on('click', function () {
    $('header .container.mainnav.mobilenav #navbarMainMenuMobile').children("ul").toggleClass("open");
    $('.page .sticky-contact').toggleClass("display-none");
});

//Onklick auf Mobile Arrows
$('header .container.mainnav.mobilenav #navbarMainMenuMobile ul.navbar-nav').children("li").children("span").on('click', function (event) {
    event.preventDefault();
    $(this).parent().find('ul.level2').removeClass('closed');
    $(this).parent().find('ul.level2').addClass('open');
    $(this).parent().parent().addClass('closed');
});

$('header .container.mainnav.mobilenav #navbarMainMenuMobile ul.level2').children("li").children("span").on('click', function (event) {
    event.preventDefault();
    $(this).parent().find('ul.level3').removeClass('closed');
    $(this).parent().find('ul.level3').addClass('open');
    $(this).parent().parent().addClass('closed');
});


$('header .container.mainnav.mobilenav #navbarMainMenuMobile ul.level2 li a.back').on('click', function (event) {
    event.preventDefault();
    if ($(this).parent().parent().hasClass("level2")) {
        $(this).parent().parent().removeClass("open");
        $(this).parent().parent().addClass("closed");
        $('header .container.mainnav.mobilenav #navbarMainMenuMobile ul.navbar-nav').removeClass("closed");
    } else {
        $(this).parent().parent().removeClass("open");
        $(this).parent().parent().addClass("closed");
        $('header .container.mainnav.mobilenav #navbarMainMenuMobile ul.level2').removeClass("closed");
    }
});

//Höhe der Navigation beim klick auf Pfeil in mobiler Navi anpassen
$('header .container.mainnav.mobilenav #navbarMainMenuMobile ul').find('li').children('span').on('click', function (event) {
    var nextMenuHeight = $(window).height() - $('#navhead').outerHeight(true) - $('#metamenu').outerHeight(true);
    $('#navbarMainMenuMobile').animate({
        height: nextMenuHeight
    }, "fast", function () {});
});

$('header .container.mainnav.mobilenav #navbarMainMenuMobile ul').find('li').children('a.back').on('click', function (event) {
    var backMenuHeight = $(this).parent().parent().parent().parent().height();
    $('#navbarMainMenuMobile').animate({
        height: backMenuHeight + 56
    }, "fast", function () {});
});

/**********************************************/
/*          Dropdown                          */
/**********************************************/
var options = [];

$('.dropdown-menu a').on('click', function (event) {

    var $target = $(event.currentTarget),
        val = $target.attr('data-value'),
        $inp = $target.find('input'),
        idx;

    if ((idx = options.indexOf(val)) > -1) {
        options.splice(idx, 1);
        setTimeout(function () {
            $inp.prop('checked', false)
        }, 0);
    } else {
        options.push(val);
        setTimeout(function () {
            $inp.prop('checked', true)
        }, 0);
    }

    $(event.target).blur();

    return false;
});

/**********************************************/
/*          Menu Sticky                       */
/**********************************************/

window._isScrolled = false;
var menu = $('.page #main-header .container-fluid').first();
var teaserbanner = $(".page .teaser .container-fluid").last();
var menuforpadding = $(".page header .mainnav .navbar-nav a.nav-link");

var searchbox = $(".searcharea");
var navhover = $(".navhover");
var sticky = $(".sticky-contact");

$(window).on('scroll', false, function () {

    navhover.removeAttr('style');
    searchbox.slideUp();
    sticky.removeClass('open');

    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if ((scrollTop > 40) && !window._isScrolled) {
        window._isScrolled = true;
        menu.addClass('scrolled');
        teaserbanner.css("margin-top", "62px");
    } else if (scrollTop <= 40 && window._isScrolled) {
        window._isScrolled = false;
        menu.removeClass('scrolled');
        teaserbanner.removeAttr("style");
    }
});
/**********************************************/
/*          Suche                             */
/**********************************************/

$('.page .teaser').find('.openCloseSearch').on('click', function (event) {
    event.preventDefault();
    $('.page .searcharea').slideToggle();
    var searchInput = document.querySelector('#topSearch');
    if (false === searchInput instanceof HTMLElement) {
        return;
    }
    searchInput.focus();
});

/**********************************************/
/*          Helper Slider                     */
/**********************************************/

$("#headerCarousel").find(".carousel-caption").children("p").each(function (index) {
    if (index == 1 || index == 3) {
        $(this).addClass("slideHeader");
    }
});

jQuery('.carousel').carousel();
jQuery(".carousel").swipe({
    swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
        if (direction == 'left') $(this).carousel('next');
        if (direction == 'right') $(this).carousel('prev');
    },
    allowPageScroll: "vertical"
});